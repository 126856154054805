var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{attrs:{"label":_vm.label,"label-for":_vm.id,"invalid-feedback":_vm.getFieldError(_vm.field),"state":!_vm.fieldHasError(_vm.field),"aria-required":_vm.required}},[_c('Editor',{attrs:{"disabled":_vm.isPosting,"api-key":"hnz74s7qqqs8rd77c7bip7isowbw7bzy5bml7pybxcdliet4","init":{
      height: 300,
      toolbar: 'undo redo | styleselect | bold italic | numlist bullist',
      plugins: 'paste lists advlist',
      contextmenu: 'paste',
      convert_fonts_to_spans: true,
      paste_enable_default_filters: true,
      paste_data_images: false,
      entity_encoding: 'raw',
      menubar: false,
      valid_elements:
        'h1,h2,h3,h4,h5,h6,span,p,strong,b,i,ul,li,ol,u,br,strike,span[style],em',
      paste_word_valid_elements:
        'h1,h2,h3,h4,h5,h6,span,p,strong,b,i,ul,li,ol,u,br,strike,span[style],em',
      paste_retain_style_properties: 'text-decoration',
      invalid_styles: 'font-weight'
    },"modelEvents":"change input keyup undo redo"},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }