<template>
  <b-form-group
    :label="label"
    :label-for="id"
    :invalid-feedback="getFieldError(field)"
    :state="!fieldHasError(field)"
    :aria-required="required"
  >
    <Editor
      :disabled="isPosting"
      api-key="hnz74s7qqqs8rd77c7bip7isowbw7bzy5bml7pybxcdliet4"
      v-model="inputValue"
      :init="{
        height: 300,
        toolbar: 'undo redo | styleselect | bold italic | numlist bullist',
        plugins: 'paste lists advlist',
        contextmenu: 'paste',
        convert_fonts_to_spans: true,
        paste_enable_default_filters: true,
        paste_data_images: false,
        entity_encoding: 'raw',
        menubar: false,
        valid_elements:
          'h1,h2,h3,h4,h5,h6,span,p,strong,b,i,ul,li,ol,u,br,strike,span[style],em',
        paste_word_valid_elements:
          'h1,h2,h3,h4,h5,h6,span,p,strong,b,i,ul,li,ol,u,br,strike,span[style],em',
        paste_retain_style_properties: 'text-decoration',
        invalid_styles: 'font-weight'
      }"
      modelEvents="change input keyup undo redo"
    />
  </b-form-group>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import FormGroupWithValidationMixin from "@/components/FormGroups/FormGroupWithValidationMixin";

export default {
  mixins: [FormGroupWithValidationMixin],
  components: {
    Editor
  },
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
