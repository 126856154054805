<template>
  <b-form-group
    :label="label"
    :label-for="id"
    :invalid-feedback="getFieldError(field)"
    :state="!fieldHasError(field)"
    :aria-required="required"
  >
    <gmap-autocomplete
      :disabled="isPosting"
      ref="gmap"
      :options="{
        fields: ['geometry', 'formatted_address', 'address_components']
      }"
      :value="inputValue"
      class="form-control"
      :select-first-on-enter="true"
      type="text"
      :name="field"
      :placeholder="placeholder"
      @input="setAddress"
      @keypress.enter="$event.preventDefault()"
      @place_changed="setAddress"
    />
  </b-form-group>
</template>

<script>
import FormGroupWithValidationMixin from "@/components/FormGroups/FormGroupWithValidationMixin";

export default {
  mixins: [FormGroupWithValidationMixin],
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    setAddress() {
      this.inputValue = this.$refs.gmap.$refs.input.value;
    }
  }
};
</script>
