<template>
  <b-form-group
    :label="label"
    :invalid-feedback="getFieldError(field)"
    :state="!fieldHasError(field)"
    :aria-required="required"
    class="file-form-group"
  >
    <b-form-file
      v-model="inputValue"
      :disabled="isPosting"
      :required="required"
      :accept="accept"
      :placeholder="$gettext('No file chosen')"
      :browse-text="$gettext('Browse')"
    />
  </b-form-group>
</template>

<script>
import FormGroupWithValidationMixin from "@/components/FormGroups/FormGroupWithValidationMixin";

export default {
  mixins: [FormGroupWithValidationMixin],
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    accept: {
      type: String,
      default: ""
    }
  }
};
</script>
